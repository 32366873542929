import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

const SUPPORTED_LANGUAGES = ['en', 'es'];

// Get load path based on current nev
const getLoadPath = (env = process.env.RAILS_ENV) => {
  switch (env) {
    case 'test': // Use mock file iff in test env
      return '/locales/__mocks__/en.json';
    default:
      return '/locales/{{lng}}.json';
  }
};

export const initI18nOptions = {
  fallbackLng: 'en',
  debug: false,
  whitelist: SUPPORTED_LANGUAGES,
  detection: {
    checkWhitelist: true,
  },
  backend: {
    loadPath: getLoadPath(),
  },
};

i18n.use(Backend).use(LanguageDetector).init(initI18nOptions);

export default i18n;
